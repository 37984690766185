import {ref, watch} from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from "vue-i18n";
import { formatDatePicker, formatDate } from "@/javascript/lib/date_helper.js";
import { useUsersStore } from "@/javascript/stores/users.js";
import { useAuthenticationStore } from "@/javascript/stores/authentication.js";

export const useModalStore = defineStore('modal', () => {
  const modalVisibility = ref(false);
  const modalType = ref("");
  const modalTypeExtraParam = ref(null);
  const modalTitle = ref("");
  const modalBody = ref("");
  const negativeButtonText = ref("");
  const positiveButtonText = ref("");
  const positiveHandleTrigger = ref(false);
  const modalErrors = ref([]);

  // One off variables for various data entry modals
  const manualVote = ref({});
  const newMember = ref({});
  const newEvent = ref({});
  const newCharity = ref({});
  const newTeam = ref({});
  const existingUser = ref(null); // Used for New Member form - check for existing user
  const editProfile = ref({});
  const image = ref(null);

  // Stores
  const usersStore = useUsersStore();
  const authenticationStore = useAuthenticationStore();

  // I18n
  const { t } = useI18n();

  const setModal = (modal, extraParam = null) => {
    modalType.value = modal;
    modalTypeExtraParam.value = extraParam;

    switch (modalType.value) {
      case 'confirm_vote':
        setConfirmVoteModal();
        break;
      case 'approve_member':
        setApproveVoteMember();
        break;
      case 'deny_member':
        setDenyVoteMember();
        break;
      case 'manual_vote':
        setManualVote({
          name: null,
          voting_event_id: null,
          voting_event_charity_id: t('modal.manual_vote.select_charity'),
        });
        setManualVoteModal();
        break;
      case 'approved_votes':
        setApprovedVotesModal();
        break;
      case 'event_details':
        setEventDetailsModal();
        break;
      case 'new_member':
        setNewMember({
          first_name: null,
          last_name: null,
          email: null,
          employer_name: null,
          address: null,
          city: null,
          postal_code: null,
          phone_number: null,
          role: t('modal.new_member.form.select_role'),
          team_id: t('modal.new_member.form.select_team'),
        });
        setNewMemberModal();
        break;
      case 'edit_member':
        setNewMember({
          user_id: modalTypeExtraParam.value.user.id,
          first_name: modalTypeExtraParam.value.user.first_name,
          last_name: modalTypeExtraParam.value.user.last_name,
          email: modalTypeExtraParam.value.user.email,
          employer_name: modalTypeExtraParam.value.user.employer_name,
          address: modalTypeExtraParam.value.user.address,
          city: modalTypeExtraParam.value.user.city,
          postal_code: modalTypeExtraParam.value.user.postal_code,
          phone_number: modalTypeExtraParam.value.user.phone_number,
          member_id: modalTypeExtraParam.value.id,
          role: modalTypeExtraParam.value.role,
          team_id: modalTypeExtraParam.value.team != null ? modalTypeExtraParam.value.team.id : t('modal.new_member.form.select_team'),
        });
        setEditMemberModal();
        break;
      case 'new_event':
        setNewEvent({
          start_time: null,
          end_time: null,
          description: null,
          address: null,
          city: null,
          postal_code: null,
          charities: [],
        });
        setNewEventModal();
        break;
      case 'edit_event':
        setNewEvent({
          id: modalTypeExtraParam.value.event.id,
          start_time: formatDatePicker(modalTypeExtraParam.value.event.start_time),
          end_time: formatDatePicker(modalTypeExtraParam.value.event.end_time),
          description: modalTypeExtraParam.value.event.description,
          address: modalTypeExtraParam.value.event.address,
          city: modalTypeExtraParam.value.event.city,
          postal_code: modalTypeExtraParam.value.event.postal_code,
          charities: modalTypeExtraParam.value.event.charities,
        });
        setEditEventModal();
        break;
      case 'cancel_event':
        setCancelEventModal();
        break;
      case 'new_charity':
        setNewCharity({
          name: null,
          address: null,
          city: null,
          description: null,
          video_url: null,
        });
        setNewCharityModal();
        break;
      case 'edit_charity':
        setNewCharity({
          id: modalTypeExtraParam.value.id,
          name: modalTypeExtraParam.value.name,
          address: modalTypeExtraParam.value.address,
          city: modalTypeExtraParam.value.city,
          description: modalTypeExtraParam.value.description,
          video_url: modalTypeExtraParam.value.video_url,
        });
        setEditCharityModal();
        break;
      case 'cancel_charity':
        setCancelCharityModal();
        break;
      case 'new_team':
        setNewTeam({
          name: null,
        });
        setNewTeamModal();
        break;
      case 'edit_team':
        setNewTeam({
          id: modalTypeExtraParam.value.id,
          name: modalTypeExtraParam.value.name,
        });
        setEditTeamModal();
        break;
      case 'cancel_team':
        setCancelTeamModal();
        break;
      case 'edit_profile':
        setEditProfile({
          id: modalTypeExtraParam.value.id,
          first_name: modalTypeExtraParam.value.first_name,
          last_name: modalTypeExtraParam.value.last_name,
          email: modalTypeExtraParam.value.email,
          employer_name: modalTypeExtraParam.value.employer_name,
          address: modalTypeExtraParam.value.address,
          city: modalTypeExtraParam.value.city,
          postal_code: modalTypeExtraParam.value.postal_code,
          phone_number: modalTypeExtraParam.value.phone_number,
        });
        setEditProfileModal();
        break;
      case 'email_invites':
        setEmailInvites();
        break;
    }
  };

  const setModalVisibility = (value) => {
    modalVisibility.value = value;
  };

  const setModalTitle = (value) => {
    modalTitle.value = value;
  };

  const setModalBody = (value) => {
    modalBody.value = value;
  };

  const setModalNegativeText = (value) => {
    negativeButtonText.value = value;
  };

  const setModalPositiveText = (value) => {
    positiveButtonText.value = value;
  };

  const setManualVote = (value) => {
    manualVote.value = value;
  };

  const setNewMember = (value) => {
    newMember.value = value;
  };

  const setNewEvent = (value) => {
    newEvent.value = value;
  };

  const setNewCharity = (value) => {
    newCharity.value = value;
  };

  const setNewTeam = (value) => {
    newTeam.value = value;
  };

  const setExistingUser = (value) => {
    existingUser.value = value;
  };

  const setEditProfile = (value) => {
    editProfile.value = value;
  };

  const setImage = (value) => {
    image.value = value;
  };

  const setModalErrors = (value) => {
    modalErrors.value = value;
  };

  const setPositiveHandleTrigger = (value) => {
    positiveHandleTrigger.value = value;
  };

  const setConfirmVoteModal = () => {
    setModalTitle(t('modal.confirm_vote.title'));
    setModalBody(`${t('modal.confirm_vote.body_1')}<b>${modalTypeExtraParam.value}</b>${t('modal.confirm_vote.body_2')}`);
    setModalNegativeText(t('modal.confirm_vote.negative'));
    setModalPositiveText(t('modal.confirm_vote.positive'));
    setModalVisibility(true);
  };

  const setApproveVoteMember = () => {
    setModalTitle(t('modal.approve_member.title'));
    setModalBody(`${t('modal.approve_member.body_1')}<b>${modalTypeExtraParam.value.user.first_name} ${modalTypeExtraParam.value.user.last_name}</b>${t('modal.approve_member.body_2')}`);
    setModalNegativeText(t('modal.approve_member.negative'));
    setModalPositiveText(t('modal.approve_member.positive'));
    setModalVisibility(true);
  };

  const setDenyVoteMember = () => {
    setModalTitle(t('modal.disable_member.title'));
    setModalBody(`${t('modal.disable_member.body_1')}<b>${modalTypeExtraParam.value.user.first_name} ${modalTypeExtraParam.value.user.last_name}</b>${t('modal.disable_member.body_2')}`);
    setModalNegativeText(t('modal.disable_member.negative'));
    setModalPositiveText(t('modal.disable_member.positive'));
    setModalVisibility(true);
  };

  const setManualVoteModal = () => {
    setModalTitle(t('modal.manual_vote.title'));
    setModalBody(t('modal.manual_vote.body_1'));
    setModalNegativeText(t('modal.manual_vote.negative'));
    setModalPositiveText(t('modal.manual_vote.positive'));
    setModalVisibility(true);
  };

  const setApprovedVotesModal = () => {
    setModalTitle(t('modal.approved_votes.title'));
    setModalBody(`${t('modal.approved_votes.body_1')}<b>${modalTypeExtraParam.value.name}</b>.`);
    setModalNegativeText(t('modal.approved_votes.negative'));
    setModalVisibility(true);
  };

  const setEventDetailsModal = () => {
    setModalTitle(t('modal.event_details.title'));
    setModalNegativeText(t('modal.event_details.negative'));
    setModalVisibility(true);
  };

  const setNewMemberModal = () => {
    setModalTitle(t('modal.new_member.title'));
    setModalBody(t('modal.new_member.body_1'));
    setModalNegativeText(t('modal.new_member.negative'));
    setModalPositiveText(t('modal.new_member.positive'));
    setModalVisibility(true);
  };

  const setEditMemberModal = () => {
    setModalTitle(t('modal.edit_member.title'));
    setModalBody(t('modal.edit_member.body'));
    setModalNegativeText(t('modal.edit_member.negative'));
    setModalPositiveText(t('modal.edit_member.positive'));
    setModalVisibility(true);
  };

  const setNewEventModal = () => {
    setModalTitle(t('modal.new_event.title'));
    setModalBody(t('modal.new_event.body'));
    setModalNegativeText(t('modal.new_event.negative'));
    setModalPositiveText(t('modal.new_event.positive'));
    setModalVisibility(true);
  };

  const setEditEventModal = () => {
    setModalTitle(t('modal.edit_event.title'));
    setModalBody(t('modal.edit_event.body'));
    setModalNegativeText(t('modal.edit_event.negative'));
    setModalPositiveText(t('modal.edit_event.positive'));
    setModalVisibility(true);
  };

  const setCancelEventModal = () => {
    setModalTitle(t('modal.cancel_event.title'));
    setModalBody(`${t('modal.cancel_event.body_1')}<b>${formatDate(modalTypeExtraParam.value.start_time)}</b>${t('modal.cancel_event.body_2')}`);
    setModalNegativeText(t('modal.cancel_event.negative'));
    setModalPositiveText(t('modal.cancel_event.positive'));
    setModalVisibility(true);
  };

  const setNewCharityModal = () => {
    setModalTitle(t('modal.new_charity.title'));
    setModalBody(t('modal.new_charity.body'));
    setModalNegativeText(t('modal.new_charity.negative'));
    setModalPositiveText(t('modal.new_charity.positive'));
    setModalVisibility(true);
  };

  const setEditCharityModal = () => {
    setModalTitle(t('modal.edit_charity.title'));
    setModalBody(t('modal.edit_charity.body'));
    setModalNegativeText(t('modal.edit_charity.negative'));
    setModalPositiveText(t('modal.edit_charity.positive'));
    setModalVisibility(true);
  };

  const setCancelCharityModal = () => {
    setModalTitle(t('modal.cancel_charity.title'));
    setModalBody(`${t('modal.cancel_charity.body_1')}<b>${modalTypeExtraParam.value.name}</b>${t('modal.cancel_charity.body_2')}`);
    setModalNegativeText(t('modal.cancel_charity.negative'));
    setModalPositiveText(t('modal.cancel_charity.positive'));
    setModalVisibility(true);
  };

  const setNewTeamModal = () => {
    setModalTitle(t('modal.new_team.title'));
    setModalBody(t('modal.new_team.body'));
    setModalNegativeText(t('modal.new_team.negative'));
    setModalPositiveText(t('modal.new_team.positive'));
    setModalVisibility(true);
  };

  const setEditTeamModal = () => {
    setModalTitle(t('modal.edit_team.title'));
    setModalBody(t('modal.edit_team.body'));
    setModalNegativeText(t('modal.edit_team.negative'));
    setModalPositiveText(t('modal.edit_team.positive'));
    setModalVisibility(true);
  };

  const setCancelTeamModal = () => {
    setModalTitle(t('modal.cancel_team.title'));
    setModalBody(`${t('modal.cancel_team.body_1')}<b>${modalTypeExtraParam.value.name}</b>${t('modal.cancel_team.body_2')}`);
    setModalNegativeText(t('modal.cancel_team.negative'));
    setModalPositiveText(t('modal.cancel_team.positive'));
    setModalVisibility(true);
  };

  const setEditProfileModal = () => {
    setModalTitle(t('modal.edit_profile.title'));
    setModalBody(t('modal.edit_profile.body_1'));
    setModalNegativeText(t('modal.edit_profile.negative'));
    setModalPositiveText(t('modal.edit_profile.positive'));
    setModalVisibility(true);
  };

  const setEmailInvites = () => {
    setModalTitle(t('modal.email_invites.title'));
    setModalBody(`<b>${modalTypeExtraParam.value}</b>${t('modal.email_invites.body')}`);
    setModalNegativeText(t('modal.email_invites.negative'));
    setModalVisibility(true);
  };

  const resetModal = () => {
    setModalVisibility(false);
    setPositiveHandleTrigger(false);
    setManualVote({});
    setNewMember({});
    setExistingUser({});
    setModalErrors([]);
    setModalTitle("");
    setModalBody("");
    setModalNegativeText("");
    setModalPositiveText("");
    setImage(null);
  };

  const negativeHandler = () => {
    resetModal();
  };

  const convertErrorsToMessages = (errorResponse) => {
    modalErrors.value = [];
    console.log('in modal store', errorResponse);
    if (errorResponse && Array.isArray(errorResponse)) {
      errorResponse.forEach((error) => {
        const detail = error.detail[0];
        const errorType = detail.error;
        const value = detail.value;

        let errorMessage = '';
        if (errorType === 'taken') {
          if (value) {
            // Convert value to title case
            const formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
            errorMessage = `Email address ${formattedValue} is already taken.`;
          } else {
            errorMessage = 'Email address is already taken.';
          }
        } else {
          errorMessage = 'An error occurred.';
        }

        modalErrors.value.push(errorMessage);
      });
    }
  };

  const updateUserProfile = () => {
    let formData = new FormData();
    formData.append('user[first_name]', editProfile.value.first_name);
    formData.append('user[last_name]', editProfile.value.last_name);
    formData.append('user[email]', editProfile.value.email);

    if (editProfile.value.employer_name) {
      formData.append('user[employer_name]', editProfile.value.employer_name);
    }
    if (editProfile.value.address) {
      formData.append('user[address]', editProfile.value.address);
    }
    if (editProfile.value.city) {
      formData.append('user[city]', editProfile.value.city);
    }
    if (editProfile.value.postal_code) {
      formData.append('user[postal_code]', editProfile.value.postal_code);
    }
    if (editProfile.value.phone_number) {
      formData.append('user[phone_number]', editProfile.value.phone_number);
    }
    if (image.value) {
      formData.append('user[image]', image.value);
    }

    usersStore.updateUser(formData, editProfile.value.id)
      .then((response) => {
        authenticationStore.setUserCurrentUser(response);
        resetModal();
      })
      .catch(error => {
        setPositiveHandleTrigger(false);
        convertErrorsToMessages(error);
      });
  };

  // Doing this here as the modal could be opened from any page
  watch(() => positiveHandleTrigger.value, () => {
    switch(modalType.value) {
      case 'edit_profile':
        updateUserProfile();
        break;
    }
  });

  return {
    modalVisibility,
    modalType,
    modalTypeExtraParam,
    modalTitle,
    modalBody,
    negativeButtonText,
    positiveButtonText,
    positiveHandleTrigger,
    modalErrors,
    manualVote,
    newMember,
    newEvent,
    newCharity,
    newTeam,
    existingUser,
    editProfile,
    image,
    setModal,
    setModalVisibility,
    setModalTitle,
    setModalBody,
    setNewMember,
    setExistingUser,
    setModalNegativeText,
    setModalPositiveText,
    setPositiveHandleTrigger,
    setConfirmVoteModal,
    setImage,
    resetModal,
    negativeHandler,
    convertErrorsToMessages,
  };
});
