import { ref } from 'vue';
import { defineStore } from 'pinia';
import { fetchData, createApi } from "@/javascript/lib/api.service.js";

export const useChaptersStore = defineStore('chapters', () => {
  const chapters = ref(null);
  const lastParams = ref(null);

  const getChapters = async (params = null, override = false) => {
    try {
      if (!override && (params === null || params === lastParams.value) && chapters.value) {
        return chapters.value;
      }
      let url = '/chapters';
      chapters.value = await fetchData(url, params);
      lastParams.value = params;
      return chapters.value;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const searchChapters = async (params = null) => {
    try {
      let url = '/chapters';
      return await fetchData(url, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const getChapterFromPath = (path) => {
    return chapters.value.find((chapter) => chapter.unique_path === path);
  };

  const getChapterFromId = (id) => {
    return chapters.value.find((chapter) => chapter.id === id);
  };

  const sendEmailInvites = async (id) => {
    try {
      let url = `/chapters/${id}/send_email_invites`;
      return await createApi(url);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  return {
    chapters,
    getChapters,
    searchChapters,
    getChapterFromPath,
    getChapterFromId,
    sendEmailInvites,
  };
});
